.fc .fc-daygrid-day-frame:hover {
  background: #f4f9ff;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.fc-highlight {
  background: rgba(188, 232, 241, 0.3) !important;
  color: gray !important;
  z-index: 0;
}