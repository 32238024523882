* {
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
}

//input-phone
.selected-flag {
  border-radius: 10px !important;
  margin-inline-start: 15px !important;
}

.react-tel-input .flag {
  transform: scale(1.3);
}

.codeListInput {
  margin-inline-start: 15px !important;
  width: calc(100% - 15px) !important;
}

.codeListInput:focus {
  border: 1px solid #a5c6d5 !important;
}

.viewClassNames {
  background-color: white;
  border-radius: 10px;
  color: #a4aac7;
  width: 100%;
  // padding: 15px;

  table {
    border: none !important;
    padding: 15px;

    th,
    tr,
    td {
      border: none !important;
    }

    th {
      padding-bottom: 15px;
    }
  }
}

.eventClassNames {
  text-align: center;
  font-weight: bold;
  width: 90%;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 5px !important;
  font-size: 12px !important;
}

.fc-day-today {
  background-color: rgba(234, 244, 255, 0.5) !important;
}

.fc-daygrid-day-number {
  margin-right: auto !important;
}

//pagination component
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  direction: ltr;

  .page-item {
    color: #616871;
    opacity: 0.5;
    margin-inline: 12px;
  }

  .active_page,
  .navigate {
    opacity: 1;
  }
}

//navlink style
.activeClassName a {
  background-color: #eaf4ff;
  color: #1ba3ef;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.react-datepicker__time-container {
  width: 300px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__time-box {
  width: 100% !important;
}

.css-en-12m2lu0 {
  width: 100% !important;
}

//emojies
.emoji-picker-react .emoji-scroll-wrapper {
}

//scroll bar
/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #ccc;
  box-shadow: inset 0 0 3px #ccc;
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 7px;
}

//rating
.rating-workshop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  width: 300px;
}
