.react-datepicker{
  
    border-radius: 0 0 24px 24px !important;
 
}

.react-datepicker-popper {
  z-index: 111 !important;
}

.calen {
  background-color: white;
  border: none;
  box-shadow: 0 0 5px 0px #e0e0e0;



  .react-datepicker__header {
    background-color: white;
    border: none;
    margin-top: 20px;

    .react-datepicker__current-month {
      margin-bottom: 15px;
      color: #616871;
      font-size: 16px;
    }
  }

  .react-datepicker__day-name {
    width: 2.7rem;
    color: #616871;
    margin-bottom: 10px;
  }

  .react-datepicker__month {
    width: 351px;
    margin-bottom: 15px;
  }

  .react-datepicker__week {
    >div {
      width: 2.7rem;
      color: #616871;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
      background: #eaf4ff;
      color: #1ba3ef;
    }

    .react-datepicker__day--outside-month {
      color: #a4aac7;
      pointer-events: none;
    }
  }

  .react-datepicker__navigation {
    top: 30px;
  }

  .react-datepicker__navigation--previous {
    left: 40px;
  }

  .react-datepicker__navigation--next {
    right: 40px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    padding: 5px;
    width: 5rem;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: #eaf4ff;
    color: #1ba3ef;
  }

  .react-datepicker__today-button {
    border-top: 0;
    padding-block: 10px;
    background: #21C6E1;
    color: white !important;
    font-size: 16px;
    text-transform: capitalize;
    border-radius: 0 0 20px 20px;
    -webkit-border-radius: 0 0 20px 20px;
    -moz-border-radius: 0 0 20px 20px;
    -ms-border-radius: 0 0 20px 20px;
    -o-border-radius: 0 0 20px 20px;

  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background: #21C6E1;
    color: white !important;
  }

  .react-datepicker__week .react-datepicker__day--selected {
    background: #eaf4ff;
    color: #1ba3ef !important;
  }

  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}